<template>
  <page-container title="监控终端分组" :breadcrumb="breadcrumb">
    <div class="box-container" style="margin-top: 10px;">
      <div v-if="!liftpropertytype" class="box-container-inner">
        <div class="simple-query">
          <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="监控终端组名称" prop="monitorgroupname">
              <a-input v-model.trim="queryParams.monitorgroupname" placeholder="请输入"></a-input>
            </a-form-model-item>
            <div class="query-btns" style="float: right">
              <a-button @click="resetQueryParams">重置</a-button>
              <a-button type="primary" class="after-btn" @click="getTableData(true)">查询</a-button>
            </div>
          </a-form-model>
        </div>
      </div>
    </div>
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="table-header">
          <h3 class="table-title">监控终端组列表</h3>
          <div class="table-btns">
            <a-button type="primary" @click="showModal('add')"><a-icon type="plus"></a-icon>新建</a-button>
            <a-icon type="redo" class="after-btn table-refresh" @click="getTableData(true)"></a-icon>
          </div>
        </div>
        <a-table :columns="tableColumns" :data-source="tableData" row-key="monitorgroupid" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
          <span slot="monitorinfoList" slot-scope="value">
            {{value&&value.length?JSON.stringify(value):''}}
          </span>
          <span slot="operation" slot-scope="value, record">
            <a-dropdown>
              <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                <a-icon type="menu"/>
              </a>
              <a-menu slot="overlay" @click="operationClick">
                <a-menu-item :key="'detail-'+record.monitorgroupid">详情</a-menu-item>
                <a-menu-item :key="'edit-'+record.monitorgroupid">修改</a-menu-item>
                <a-menu-item :key="'delete-'+record.monitorgroupid">删除</a-menu-item>
                <a-menu-item :key="'manager-'+record.monitorgroupid">关联设备</a-menu-item>
<!--                <a-menu-item :key="'update-'+record.monitorgroupid">版本升级</a-menu-item>-->
              </a-menu>
            </a-dropdown>
          </span>
        </a-table>
        <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer @change="pageChange" @showSizeChange="onShowSizeChange" :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
          <template slot="buildOptionText" slot-scope="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
    </div>
    <add-or-edit-modal :visible.sync="modalVisible" :show-type="modalShowType" :detailData="modalDetailData" @get-operation-result="getTableData" :liftpropertytype="liftpropertytype"></add-or-edit-modal>
    <monitor-manager :visible.sync="groupManagerVisible" :params="{monitorgroupid:monitorgroupid}"></monitor-manager>
  </page-container>
</template>
<script>
import {getItemFromArrayByKey} from 'U'
import store from '@/store'
import moment from 'moment'
import pagination from '@/mixins/pagination'
import {
  getMonitorgroupListByCondition,
  deleteMonitorgroup, stopClients,
} from 'A/monitoring'
import addOrEditModal from './addOrEditModal'
import MonitorManager from './MonitorManager'
import {getCache} from "U/index";
export default {
  name: 'monitorGroupList',
  mixins: [pagination],
  components: {
    addOrEditModal,
    MonitorManager,
  },
  props: ['liftpropertytype'],
  data() {
    return {
      moment,
      breadcrumb: [
        {
          name: '网络管理',
          path: ''
        },
        {
          name: '监控软终端',
          path: ''
        },
        {
          name: '设备分组',
          path: ''
        },
      ],
      queryParams: {
        monitorgroupname: '',
      },
      tableColumns: [
        {
          title: '设备组名称',
          dataIndex: 'monitorgroupname',
          key: 'monitorgroupname',
          ellipsis: true,
        },
        {
          title: '创建者',
          dataIndex: 'creator',
          key: 'creator',
          ellipsis: true,
        },
        {
          title: '描述',
          dataIndex: 'monitorgroupdesc',
          key: 'monitorgroupdesc',
          ellipsis: true,
        },
        {
          title: '操作',
          key: 'operation',
          width: 100,
          scopedSlots: { customRender: 'operation' }
        },
      ],
      tableData: [],
      tableLoading: false,
      modalVisible: false,
      modalShowType: '',
      modalDetailData: null,
      groupManagerVisible: false,
      monitorgroupid: '',
      monitornum:'',
      counts:'',
      username:'',
      monitornums:[],
      monitornames:[],
      sum:0,
    }
  },
  beforeRouteEnter(to, from, next) {
    let toTab = getItemFromArrayByKey(store.state.main.tabs, 'id', to.name);
    if(!toTab) {
      store.commit('delKeepAlive', to.name)
    }
    next()
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.getTableData();
    },
    resetQueryParams() {
      this.$refs.queryForm.resetFields();
    },
    getTableData(firstPage) {
      if (firstPage) {
        this.currentPage = 1;
      }
      let logininfo = getCache('logininfo', true);
      if (logininfo) {
        this.username = logininfo.usernames;
        this.usertype = logininfo.usertype;
      }
      this.tableLoading = true;
      let params = {
        ...this.queryParams,
        creator: this.usertype==0?"":this.username,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize
      };
      if (params.monitorgroupname == '') {
        getMonitorgroupListByCondition(params).then(res => {
          this.tableLoading = false;
          if (res && res.returncode == '0') {
            this.tableData = res.item;
            this.counts = res.count;
          }
        }).catch(err => {
          this.tableLoading = false;
        })
      }
      getMonitorgroupListByCondition(params).then(res => {
        this.tableLoading = false;
        if (res && res.returncode == '0') {
          this.tableData = res.item;
          this.pagination.total = res.count;
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    operationClick({key}) {
      let arr = key.split('-');
      let type = arr[0];
      let monitorgroupid = arr[1];
      let record = getItemFromArrayByKey(this.tableData, 'monitorgroupid', monitorgroupid);
      if (type == 'detail') {
        this.showModal('detail', '', record)
      } else if (type == 'edit') {
        this.showModal('edit', '', record)
      } else if (type == 'delete') {
        this.deleteConfirm('', record);
      } else if (type == 'manager') {
        this.managerMonitor('', record);
      } else if (type == 'update') {
        this.updateVersion('', record);
      }
    },
    //延时
    sleep(numberMillis) {
      var now = new Date();
      var exitTime = now.getTime() + numberMillis;
      while(true) {
        now = new Date();
        if (now.getTime() > exitTime)
          return;
      }
    },
    deleteConfirm(value, record) {
      this.$confirm('确定要删除该数据？',{
        type:'warning',
        centered:true,
      }).then(()=>{
        this.delete(record.monitorgroupid);
      }).catch(()=>{
      });
    },
    delete(monitorgroupid) {
      this.showLoading();
      if(monitorgroupid) {
        let params = {
          monitorgroupid
        };
        deleteMonitorgroup(params).then(res => {
          this.hideLoading();
          if(res && res.returncode == '0') {
            this.$message.success('操作成功');
            this.getTableData();
          }else {
            this.$message.error(res.errormsg||'操作失败');
          }
        })
      }
    },
    showModal(type, value, record) {
      this.modalShowType = type;
      if(type == 'add') {
        this.modalDetailData = null;
      }else {
        this.modalDetailData = record;
      }
      this.modalVisible = true;
    },
    managerMonitor(value, record) {
      this.monitorgroupid = record.monitorgroupid;
      this.groupManagerVisible = true;
    },
  }
}
</script>
<style lang="scss" scoped>
</style>