<template>
  <a-modal v-model="modalVisible" title="关联终端" :width="900" :centered="true">
    <template slot="footer">
      <a-button @click="modalVisible=false">关闭</a-button>
    </template>
    <div class="select-modal-container">
      <a-form-model ref="queryForm" :model="queryParams" layout="inline">
        <a-form-model-item>
          <a-button @click="addMonitorModel=true">增加</a-button>
          <a-button type="primary" style="margin-left: 10px;" @click="deleteConfirm()">删除</a-button>
        </a-form-model-item>
      </a-form-model>
      <a-table :columns="tableColumns" :data-source="tableData" row-key="monitorinfoid" :row-selection="{ type: selectType, selectedRowKeys: selectedRowKeys, onSelect: onSelectRow, onSelectAll: onSelectAllRows }" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 16px;" :pagination="false">
        <span slot="isonline" slot-scope="value">
          <a-tag :color="value=='1'?'green':'red'">{{value=='1'?'在线':'离线'}}</a-tag>
        </span>
      </a-table>
      <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" @change="pageChange" :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
        <template slot="buildOptionText" slot-scope="props">
          <span>{{ props.value }}条/页</span>
        </template>
      </a-pagination>
    </div>
    <add-monitor :visible.sync="addMonitorModel" :params="params" @get-operation-result="getTableData"></add-monitor>
  </a-modal>
</template>
<script>
import pagination from '@/mixins/pagination'
import { getMonitorgroupdevListByCondition, deleteMonitorgroupdev } from 'A/monitoring'
import AddMonitor from './AddMonitor'
export default {
  components: {
    AddMonitor
  },
  mixins: [pagination],
  props: {
    visible: {
      default: false
    },
    selectType: {
      default: 'checkbox'
    },
    defaultSelected: {
      default() {
        return []
      }
    },
    params: {
      default: null
    }
  },
  data() {
    return {
      modalVisible: false,
      terminaltypes: [],
      queryParams: {
        monitorname: '',
        monitornum: '',
        terminaltype: '',
      },
      tableLoading: false,
      tableColumns: [
        {
          title: '终端名称',
          dataIndex: 'monitorname',
          key: 'monitorname',
          ellipsis: true,
        },
        {
          title: '终端ID',
          dataIndex: 'monitornum',
          key: 'monitornum',
          ellipsis: true,
        },
        {
          title: '版本号',
          dataIndex: 'version',
          key: 'version',
          ellipsis: true,
        },
        {
          title: '在线状态',
          dataIndex: 'isonline',
          key: 'isonline',
          ellipsis: true,
          width:80,
          scopedSlots: { customRender: 'isonline' }
        },
      ],
      tableData: [],
      selectedRows: [],
      currentPage: 1,
      pagination: {
        total: 0,
        pageSize: 5,
      },
      addMonitorModel: false,
    }
  },
  computed: {
    selectedRowKeys() {
      let currentSelectKeys = this.selectedRows.map(item => item.monitorinfoid);
      return currentSelectKeys;
    },
  },
  watch: {
    visible(val) {
      if(val) {
        this.modalVisible = true;
        this.selectedRows = this.defaultSelected || [];
        this.init();
      }else {
        this.selectedRows = []
      }
    },
    modalVisible(val) {
      if(!val) {
        this.$emit('update:visible', false);
      }
    },
    params(val) {
      if(val) {
        this.queryParams = {...this.queryParams, ...val};
      }
    },
  },
  created() {
  },
  methods: {
    init() {
      if(this.params) {
        this.queryParams = {...this.queryParams, ...this.params};
      }
      this.modalVisible = this.visible;
      this.getTableData()
    },
    resetQueryForm() {
      this.$refs.queryForm.resetFields();
    },
    getTableData(firstPage) {
      if(firstPage) {
        this.currentPage = 1;
      }
      let params = {
        ...this.queryParams,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize,
      };
      getMonitorgroupdevListByCondition(params).then(res => {
        if(res && res.returncode == '0') {
          this.tableData = res.item.map(item => {
            item.monitorinfoid = '' + item.monitorinfoid;
            return item;
          });
          this.pagination.total = res.count;
        }
      })
    },
    onSelectRow(record, selected, selectedRows, nativeEvent) {
      if(selected) {
        if(this.selectType == 'radio') {
          this.selectedRows = [record]
        }else if(this.selectedRowKeys.indexOf(record.monitorinfoid) < 0) {
          this.selectedRows.push(record);
        }
      }else {
        this.selectedRows = this.selectedRows.filter(item => item.monitorinfoid!=record.monitorinfoid)
      }
    },
    onSelectAllRows(selected, selectedRows, changeRows) {
      if(selected) {
        this.tableData.forEach(record => {
          if(this.selectedRowKeys.indexOf(record.monitorinfoid) < 0) {
            this.selectedRows.push(record);
          }
        })
      }else {
        this.tableData.forEach(record => {
          this.selectedRows = this.selectedRows.filter(item => item.monitorinfoid!=record.monitorinfoid)
        })
      }
    },
    confirm() {
      this.$emit('get-selected-rows', this.selectedRows);
      this.modalVisible = false;
    },
    deleteConfirm(value, record) {
      this.$confirm('确定要删除该数据？',{
        type:'warning',
        centered:true,
      }).then(()=>{
        this.delete();
      }).catch(()=>{
      });
    },
    delete() {
      this.showLoading();
      if(this.selectedRows.length) {
        let monitorinfoids = this.selectedRows.map(item => item.monitorinfoid).join('|');
        let params = {
          monitorgroupid: this.params.monitorgroupid,
          monitorinfoids: monitorinfoids,
        };
        deleteMonitorgroupdev(params).then(res => {
          this.hideLoading();
          if(res && res.returncode == '0') {
            this.$message.success('操作成功');
            this.getTableData();
          }else {
            this.$message.error(res.errormsg||'操作失败');
          }
        })
      }
    },
  }
}
</script>